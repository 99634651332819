import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';
import { Link } from "react-scroll";
import {FADE_DURATION} from "../../config";

const About = () => {
  const { about, contact } = useContext(PortfolioContext);
  const { paragraphOne, paragraphTwo, paragraphThree } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <>
      <section id="about">
        <Container>
          <Title title="About Me" />
          <Fade left={isDesktop} bottom={isMobile} duration={FADE_DURATION} delay={300} distance="30px">
            <div className="about-wrapper__info">
              {(paragraphOne !== "") ? (
                  <p className='about-wrapper__info-text'>
                    {paragraphOne}
                  </p>
              ) : undefined}
              {(paragraphTwo !== "") ? (
                  <p className='about-wrapper__info-text'>
                    {paragraphTwo}
                  </p>
              ) : undefined}
              {(paragraphThree !== "") ? (
                  <p className='about-wrapper__info-text'>
                    {paragraphThree}
                  </p>
              ) : undefined}

              {/*<p id='Hectre_Interview' className="about-wrapper__info-text">
                At the end of my internship at Hectre in 2022 I was interviewed on Episode 5 of the <a
                  href="https://hectre.com/resources/the-co-hort-podcast/" className="inline" target="_blank">Co-Hort
                Podcast</a>!<br/>
                Have a listen to learn more about me and hear what they had to say:<br/>

                <audio controls>
                  <source src="/hectre_interview/interview.ogg" type="audio/ogg"/>
                  <source src="/hectre_interview/interview.mp3" type="audio/mpeg"/>

                  <Link href="/hectre_interview/interview.mp3">Download to listen!</Link>
                </audio>
              </p>*/}
              <span className="d-flex mt-3">
              <span className="cta-btn cta-btn--resume" style={{cursor: "pointer"}}>
                <Link to="contact" smooth duration={FADE_DURATION}>
                  {"Let's talk"}
                </Link>
              </span>
            </span>
            </div>
          </Fade>
        </Container>
      </section>
    </>
  );
};

export default About;
